import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-footer-bar",
  templateUrl: "./footer-bar.component.html",
  styleUrls: ["./footer-bar.component.scss"]
})
export class FooterBarComponent implements OnInit {

  constructor() {}


  ngOnInit() {

  }

  chat() {
    let url = "https://servicios.saludsa.com.ec/ChatGenesis/informacion.html?x=76&y=10"
    window.open(url, "_blank")
  }
}

import { PaginationConstants } from '../utils/pagination';

export class PaginationService {

    public paginationConstants: PaginationConstants;
    private paginationConstantsInit: PaginationConstants;

    constructor(pageNumber: number = 1, pageSize: number = 12) {
        this.paginationConstants = new PaginationConstants(0, pageNumber, pageSize, 0);
        this.paginationConstantsInit = new PaginationConstants(0, pageNumber, pageSize, 0);
    }

    protected extractDataPaginated(res: any) {
        let body = res.json();
        if (body.total != undefined)
            this.paginationConstants.total = body.total;
        if (body.data != undefined) {
            this.paginationConstants.currentPageSize = body.data.length;
            return body.data || [];
        }
        return [];
    }

    public resetDefaultPaginationConstanst(): void {
        this.paginationConstants = Object.assign(new PaginationConstants(), this.paginationConstantsInit);
    }
}
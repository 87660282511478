import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LogoBarRoutingModule } from './logo-bar-routing.module';
import { LogoBarComponent } from './logo-bar.component';
import { Ng2CompleterModule } from 'ng2-completer';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [LogoBarComponent],
  imports: [
    CommonModule,
    LogoBarRoutingModule,
    Ng2CompleterModule,
    FormsModule
  ],
  exports: [LogoBarComponent]
})
export class LogoBarModule { }

import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'front-user-v2';

  constructor(public router: Router) {
    let urlGA = window.document.head.getElementsByTagName("script")[0].getAttribute("src");

      let GA_ID = urlGA.substring(urlGA.indexOf("=")+1, urlGA.length);

      this.router.events.subscribe(event => {
        if(event instanceof NavigationEnd){
          let pagina = ""
          if (event.urlAfterRedirects.startsWith("/beneficios")) {
            pagina = "/beneficios";
          } else if(event.urlAfterRedirects.startsWith("/login")) {
            pagina = "/login";
          } else {
            pagina = event.urlAfterRedirects;
          }
          
          gtag('config', GA_ID, 
            {
              'page_path': pagina
            }
          );
      }
      });
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConstantService } from './utils/services/constant.service';
import { AppConfig } from './app.config';
import { LogoBarModule } from './components/logo-bar/logo-bar.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ModalModule } from './_modal';
import { FooterBarModule } from './components/footer-bar/footer-bar.module';
import { Ng2CompleterModule } from "ng2-completer";

export function appConfigServiceFactory(appConfig: AppConfig) {
  return () => appConfig.load();
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LogoBarModule,
    FormsModule,
    ModalModule,
    FooterBarModule,
    Ng2CompleterModule
  ],
  providers: [ConstantService, AppConfig, 
    { provide: APP_INITIALIZER, useFactory: appConfigServiceFactory, deps: [AppConfig], multi: true },
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }

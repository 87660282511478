import { Component, OnInit } from '@angular/core';
import { ConstantService } from 'src/app/utils/services/constant.service';
import { CompleterService, CompleterData } from 'ng2-completer';
import { Router } from '@angular/router';
import { BusquedaGeneralService } from 'src/app/utils/services/busqueda.service';
// import { ConstantService } from 'src/app/utils/constant.service';

@Component({
  selector: 'app-logo-bar',
  templateUrl: './logo-bar.component.html',
  styleUrls: ['./logo-bar.component.scss']
})
export class LogoBarComponent implements OnInit {

  logoDrSaludAddress: string;
  activadoBuscar: boolean;
  mostrarMenuMovil: boolean;
  mostrarMenu: boolean;
  filtro:string;
  dataService: CompleterData;
  private busquedaPredictivalUrl = 'BusquedaGeneral/predictivo/';
  
  constructor(private router: Router, private completerService: CompleterService, public busquedaService:BusquedaGeneralService,private constantService: ConstantService) {
    this.dataService = completerService.remote(
      this.constantService.API_ENDPOINT + this.busquedaPredictivalUrl,
      "Valor",
      "Valor");
  }
  
  ngOnInit() {
    this.logoDrSaludAddress = 'assets/images/logo-drsalud.png';
    this.activadoBuscar = false;
    this.mostrarMenuMovil = false;
    this.mostrarMenu = true;
    this.filtro=null;
  }
  preguntasFrecuentes() {
    let url = "https://serviciosaludsa.zendesk.com/hc/es";
    window.open(url, "_blank")
  }

  activarBusqueda() {
    this.activadoBuscar = true;
    this.mostrarMenu= false;
  }

  desactivarBusqueda() {
    this.activadoBuscar = false;
    this.mostrarMenu = true;
    this.filtro=null;
  }

  abrirMenuMovil() {
    this.mostrarMenuMovil = true;
  }

  cerrarMenuMovil() {
    this.mostrarMenuMovil = false;
  }

  buscar()
  {
    this.busquedaService.paginationConstants.pageNumber = 1;
    this.busquedaService.paginationConstants.pageSize = 12;
    this.router.navigate(["buscador/busquedaGeneral/" + this.filtro]);
    this.activadoBuscar = false;
    this.mostrarMenu = true;
    this.filtro=null;
  }
}

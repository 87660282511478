import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/internal/operators';

@Injectable()
export class AppConfig {

    private config: Object = null;

    constructor(private http: HttpClient) {

    }

    public get(key: any) {
        return this.config[key];
    }

    public load() {
        return new Promise((resolve, reject) => {
            this.getConfigJson()
                .subscribe(data => {
                    this.config = data;
                    let request: any = null;
                    resolve(true);
                });
        });
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }

    private log(message: string) {
        console.log(message);
    }


    private getConfigJson() {
        return this.http.get<Object>('./assets/config.json').pipe(
            catchError(this.handleError<Object>('getConfigJson', [])));
    }
}
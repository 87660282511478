import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FooterBarRoutingModule } from './footer-bar-routing.module';
import { FooterBarComponent } from './footer-bar.component';


@NgModule({
  declarations: [FooterBarComponent],
  imports: [
    CommonModule,
    FooterBarRoutingModule
  ]
  ,
  exports: [FooterBarComponent]
})
export class FooterBarModule { }

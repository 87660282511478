import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'urgencias', loadChildren: () => import('./urgencias/urgencias.module').then(m => m.UrgenciasModule) },
  { path: 'atencionesMedicas', loadChildren: () => import('./atenciones-medicas/atenciones-medicas.module').then(m => m.AtencionesMedicasModule) },
  { path: 'hospitalizaciones', loadChildren: () => import('./hospitalizaciones/hospitalizaciones.module').then(m => m.HospitalizacionesModule) },
  { path: 'guiaMedica', loadChildren: () => import('./guia-medica/guia-medica.module').then(m => m.GuiaMedicaModule) },
  { path: 'diaDrSaludsa', loadChildren: () => import('./dia-dr-saludsa/dia-dr-saludsa.module').then(m => m.DiaDrSaludsaModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'clinicasAfiliadas', loadChildren: () => import('./hospitalizaciones/clinicas-afiliadas/clinicas-afiliadas.module').then(m => m.ClinicasAfiliadasModule) },
  { path: 'buscador', loadChildren: () => import('./buscador/buscador.module').then(m => m.BuscadorModule) },
  { path: 'view', loadChildren: () => import('./DetallesInfo/detalles-info.module').then(m => m.DetallesInfoModule) }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{anchorScrolling: "enabled",  onSameUrlNavigation: "reload", useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

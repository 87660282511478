import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';

@Injectable({
    providedIn: 'root'
})
export class ConstantService {

  API_ENDPOINT: String;
  AUTH_ENDPOINT: String;
  RESOURCES_BASE_ADDRESS: String;

  DEFAULT_NIVEL_ID: string;
  FEE_CERO_TRAMITES: string;

  GOOGLE_RECAPTCHA_SITE_KEY: string;

  IMAGES_FOLDER: String;
  IMAGES_FOLDER_LOGOS: String;
  BANNERS_FOLDER: String;
  UPLOADS_FOLDER: String;

  constructor(private config: AppConfig) {
    this.API_ENDPOINT = config.get('API_ENDPOINT');
    this.AUTH_ENDPOINT = config.get('AUTH_ENDPOINT');
    this.RESOURCES_BASE_ADDRESS = config.get('RESOURCES_BASE_ADDRESS');

    this.DEFAULT_NIVEL_ID = config.get('DEFAULT_NIVEL_ID');
    this.FEE_CERO_TRAMITES = config.get('FEE_CERO_TRAMITES');

    this.GOOGLE_RECAPTCHA_SITE_KEY = config.get('GOOGLE_RECAPTCHA_SITE_KEY');

    this.IMAGES_FOLDER = "SaludUploads/";
    this.IMAGES_FOLDER_LOGOS = "Images/Logos/";
    this.BANNERS_FOLDER = "Banners"
    this.UPLOADS_FOLDER = "SaludUploads";
  }
}
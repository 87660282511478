import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ConstantService } from './constant.service';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { PaginationConstants } from '../pagination';
import { PaginationService } from '../pagination.service';

@Injectable({
  providedIn: 'root'
})
export class BusquedaGeneralService extends PaginationService {

  private busquedaUrl = 'FiltroBusquedaController';
  private busquedaGeneralUrl = 'BusquedaGeneral';
  public paginationConstants: PaginationConstants;

  constructor(private http: HttpClient, private constantService: ConstantService) {
    super(1, 12);
    this.busquedaUrl = this.constantService.API_ENDPOINT + this.busquedaUrl;
    this.busquedaGeneralUrl = this.constantService.API_ENDPOINT + this.busquedaGeneralUrl;
  }

  getPrestadorByGeneralSearch(filter: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json', 'X-Page-Number': this.paginationConstants.pageNumber + '', 'X-Page-Size': this.paginationConstants.pageSize + '' });
    return this.http.post<any>(this.busquedaGeneralUrl+"/"+filter ,filter, { headers: headers }).pipe(
      catchError(this.handleError<any>()));
  }



  private handleError<T>() {
    return (error: any): Observable<T> => {
      let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      if (error.status != null) {
        if (error.status == "504")
          errMsg = "No existe conexión. Tiempo de espera agotado.";
        else if (error.status == "404")
          errMsg = "404";
        else if (error.status == "500")
          errMsg = "Oops ha ocurrido un error interno. Por favor intente más tarde.";
        else
          errMsg = (error._body) ? JSON.parse(error._body).Message : errMsg;
      }
      console.error(errMsg); // log to console instead
      return Observable.throw(errMsg);
    };
  }


}
